.side-drawer{
    height: 100%;
    /* background-color: rgb(50, 49, 48); */
    background-image: 
    linear-gradient(rgba(0, 0, 0, .9), rgba(0, 0, 0, .9)),
    url('../../assets/cornetto.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 300px;
    z-index: 200;
    transform: translateX(-100%);
    transition:  300ms ease-out;
}

.side-drawer.open{
    transform: translateX(0);
}

.side-drawer li{
    margin-bottom: 4%;
}

.side-drawer ul{
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
}

.drop-down{
    color: black;
}

.side-drawer a{
    color: white;
    text-decoration: none;

}

.dropdown-item a{
    color: black;
}
.side-drawer a:hover,
.side-drawer a:active{
    color: goldenrod;
}

.header{
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgb(235, 210, 192);

}

.custom-nav-link{
    padding: 1rem;
}

.social{
    color: rgb(235, 210, 192);
    display: flex;
    justify-content: space-around;
}



