/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Imbue:wght@300;400;700&family=Poppins:wght@300;400;700&display=swap");
body {
  background-color: rgb(26, 25, 25);
  color: whitesmoke;
  font-weight: 300;
  /* font-family: 'Source Sans Pro', sans-serif; */
  font-family: "Poppins", sans-serif;
}

iframe {
  max-width: 100%;
  border-radius: 5px;
}

audio {
  max-width: 100%;
}

video {
  max-width: 100%;
  border-radius: 10px;
}

@media (max-width: 321px) {
  h1 {
    font-size: 1.8rem;
    margin-top: 8px;
  }
}

.activity-scroller {
  height: 100vh;
  overflow: auto;
}

.btn {
  margin: 3px;
}

.contact-bar {
  display: inline-block;
  width: 100%;
  background-color: rgba(50, 49, 48, 0.8);
  padding: 10px;
}

.content {
  padding: 20px;
  max-width: 1300px;
  margin: auto;
}

.featured-cd-card {
  background-color: rgb(19, 18, 18);
  border-radius: 5px;
  box-shadow: 1px 1px 2px rgb(57, 56, 56);
  margin: 25px;
  padding: 10px;
  /* height: 300px; */
  border-radius: 5px;
}
.link {
  color: white;
}

.link:hover {
  text-decoration: none;
  color: goldenrod;
}

.home-project-card {
  padding: 5px;
  width: 100%;
  height: 300px;
  text-align: center;
  overflow: hidden;
  border-radius: 5px;
}

.project-card__main-content {
  display: flex;
}

.project-full-main-content {
  display: flex;
  justify-content: space-evenly;
}

.project-full-main-content-text {
  width: 50%;
}

.project-full-main-content-image img {
  max-width: 100%;
  border-radius: 10px;
  max-height: 50vh;
}
.project-full-main-content-image {
  max-width: 40%;
  min-width: 300px;
  margin: 20px 0;
}

.project-full-title {
  margin: 30px;
}

.ensemble-page-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 30px;
}

.ensemble-page-content-text {
  width: 55%;
  text-align: justify;
}
.ensemble-page-content-image {
  max-width: 40%;
  min-width: 300px;
  margin: 0 16px;
}
.ensemble-page-content-image img {
  max-width: 100%;
  max-height: 100%;
}

@media (max-width: 900px) {
  .project-card__main-content {
    flex-direction: column-reverse;
    align-items: center;
  }
  .project-full-main-content {
    flex-direction: column-reverse;
  }
  .project-full-main-content-text {
    width: 100%;
  }
  .project-full-main-content-image {
    max-width: 100%;
  }
  .project-full-title {
    margin: 30px 0;
  }
  .ensemble-page-content {
    flex-direction: column-reverse;
  }
  .ensemble-page-content-text {
    width: 100%;
  }
  .ensemble-page-content-image {
    max-width: 100%;
    margin-bottom: 20px;
  }
}

.home-project-card-overlay {
  border-radius: 5px;
}

.home-project-card-image-wrapper {
  width: 100%;
  border-radius: 5px;
  height: 150px;
  overflow: hidden;
}

.home-project-card img {
  border-radius: 5px;
  max-width: 100%;
}

.product-card {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
  background-color: rgb(33, 33, 33);
  margin: 20px;
}

.product-card-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 85px; */

  padding: 10px;
}

.project-card {
  padding: 30px;
  background-color: rgb(34, 33, 33);
  margin: 25px;
  border-radius: 5px;
}

.project-card-left-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.single-product-description {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 45px;
}

.special-btn {
  background-color: rgb(26, 25, 25);
  color: whitesmoke;
  border: none;
  border-radius: 10px;
  padding: 7px;
  margin: 2px;
}

.special-btn:hover {
  background-color: rgb(57, 56, 56);
  cursor: pointer;
  color: goldenrod !important;
  text-decoration: none;
}

.special-btn-outline {
  border: 1px solid black;
  padding: 7px;
  border-radius: 10px;
  background-color: white;
}

.special-btn-outline:hover {
  background-color: #eee;
}

.titlebar {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.sharing-buttons {
  text-align: right;
}

@media (max-width: 900px) {
  .titlebar {
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }
  .sharing-buttons {
    text-align: center;
    font-size: small;
  }
}

.welcome-text {
  text-align: center;
  max-width: 1200px;
  margin: auto;
  padding: 50px 0;
}

/*image overlays*/

.main-box {
  width: 100%;
  position: relative;
  float: left;
  margin: 0 4px;
}
.main-box img {
  position: relative;
  z-index: 1;
}

.overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  opacity: 0;
  color: black;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  background-color: #d5d5d5e2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5%;
  text-align: center;
}

.overlay p {
  font-weight: 500;
}
.main-box:hover .overlay {
  opacity: 1;
}

.gallery-img {
  width: 100%;
}

.gallery-img:hover {
  cursor: pointer;
  width: 105%;
  transition: 500ms;
}

.img-wrap {
  overflow: hidden;
  height: 0;
  padding: 50% 0;
  /* padding controls height, will always be perfectly square regardless of width */
  position: relative;
  opacity: 0.8;
}
.img-wrap img {
  min-width: 150%;
  min-height: 150%;
  max-width: 120%;
  position: absolute;
  top: 0;
  right: 0;
}

/* modal styles */
.backdrop {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}
.backdrop img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 40px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  border: 3px solid white;
}

.backdrop h5 {
  text-align: center;
  color: white;
}
