@import url(https://fonts.googleapis.com/css2?family=Imbue:wght@300;400;700&family=Poppins:wght@300;400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap'); */
body {
  background-color: rgb(26, 25, 25);
  color: whitesmoke;
  font-weight: 300;
  /* font-family: 'Source Sans Pro', sans-serif; */
  font-family: "Poppins", sans-serif;
}

iframe {
  max-width: 100%;
  border-radius: 5px;
}

audio {
  max-width: 100%;
}

video {
  max-width: 100%;
  border-radius: 10px;
}

@media (max-width: 321px) {
  h1 {
    font-size: 1.8rem;
    margin-top: 8px;
  }
}

.activity-scroller {
  height: 100vh;
  overflow: auto;
}

.btn {
  margin: 3px;
}

.contact-bar {
  display: inline-block;
  width: 100%;
  background-color: rgba(50, 49, 48, 0.8);
  padding: 10px;
}

.content {
  padding: 20px;
  max-width: 1300px;
  margin: auto;
}

.featured-cd-card {
  background-color: rgb(19, 18, 18);
  border-radius: 5px;
  box-shadow: 1px 1px 2px rgb(57, 56, 56);
  margin: 25px;
  padding: 10px;
  /* height: 300px; */
  border-radius: 5px;
}
.link {
  color: white;
}

.link:hover {
  text-decoration: none;
  color: goldenrod;
}

.home-project-card {
  padding: 5px;
  width: 100%;
  height: 300px;
  text-align: center;
  overflow: hidden;
  border-radius: 5px;
}

.project-card__main-content {
  display: flex;
}

.project-full-main-content {
  display: flex;
  justify-content: space-evenly;
}

.project-full-main-content-text {
  width: 50%;
}

.project-full-main-content-image img {
  max-width: 100%;
  border-radius: 10px;
  max-height: 50vh;
}
.project-full-main-content-image {
  max-width: 40%;
  min-width: 300px;
  margin: 20px 0;
}

.project-full-title {
  margin: 30px;
}

.ensemble-page-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 30px;
}

.ensemble-page-content-text {
  width: 55%;
  text-align: justify;
}
.ensemble-page-content-image {
  max-width: 40%;
  min-width: 300px;
  margin: 0 16px;
}
.ensemble-page-content-image img {
  max-width: 100%;
  max-height: 100%;
}

@media (max-width: 900px) {
  .project-card__main-content {
    flex-direction: column-reverse;
    align-items: center;
  }
  .project-full-main-content {
    flex-direction: column-reverse;
  }
  .project-full-main-content-text {
    width: 100%;
  }
  .project-full-main-content-image {
    max-width: 100%;
  }
  .project-full-title {
    margin: 30px 0;
  }
  .ensemble-page-content {
    flex-direction: column-reverse;
  }
  .ensemble-page-content-text {
    width: 100%;
  }
  .ensemble-page-content-image {
    max-width: 100%;
    margin-bottom: 20px;
  }
}

.home-project-card-overlay {
  border-radius: 5px;
}

.home-project-card-image-wrapper {
  width: 100%;
  border-radius: 5px;
  height: 150px;
  overflow: hidden;
}

.home-project-card img {
  border-radius: 5px;
  max-width: 100%;
}

.product-card {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
  background-color: rgb(33, 33, 33);
  margin: 20px;
}

.product-card-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 85px; */

  padding: 10px;
}

.project-card {
  padding: 30px;
  background-color: rgb(34, 33, 33);
  margin: 25px;
  border-radius: 5px;
}

.project-card-left-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.single-product-description {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 45px;
}

.special-btn {
  background-color: rgb(26, 25, 25);
  color: whitesmoke;
  border: none;
  border-radius: 10px;
  padding: 7px;
  margin: 2px;
}

.special-btn:hover {
  background-color: rgb(57, 56, 56);
  cursor: pointer;
  color: goldenrod !important;
  text-decoration: none;
}

.special-btn-outline {
  border: 1px solid black;
  padding: 7px;
  border-radius: 10px;
  background-color: white;
}

.special-btn-outline:hover {
  background-color: #eee;
}

.titlebar {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.sharing-buttons {
  text-align: right;
}

@media (max-width: 900px) {
  .titlebar {
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }
  .sharing-buttons {
    text-align: center;
    font-size: small;
  }
}

.welcome-text {
  text-align: center;
  max-width: 1200px;
  margin: auto;
  padding: 50px 0;
}

/*image overlays*/

.main-box {
  width: 100%;
  position: relative;
  float: left;
  margin: 0 4px;
}
.main-box img {
  position: relative;
  z-index: 1;
}

.overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  opacity: 0;
  color: black;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  background-color: #d5d5d5e2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5%;
  text-align: center;
}

.overlay p {
  font-weight: 500;
}
.main-box:hover .overlay {
  opacity: 1;
}

.gallery-img {
  width: 100%;
}

.gallery-img:hover {
  cursor: pointer;
  width: 105%;
  transition: 500ms;
}

.img-wrap {
  overflow: hidden;
  height: 0;
  padding: 50% 0;
  /* padding controls height, will always be perfectly square regardless of width */
  position: relative;
  opacity: 0.8;
}
.img-wrap img {
  min-width: 150%;
  min-height: 150%;
  max-width: 120%;
  position: absolute;
  top: 0;
  right: 0;
}

/* modal styles */
.backdrop {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}
.backdrop img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 40px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  border: 3px solid white;
}

.backdrop h5 {
  text-align: center;
  color: white;
}

.backdrop{
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0,0,0,.3);
    z-index: 100;
    top: 0;
    left: 0;
}
.toggle-button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
  }
  
  .toggle-button:focus {
    outline: none;
  }
  
  .toggle-button__line {
    width: 30px;
    height: 2px;
    background-color: white;
  }

  .toggle-button__line:{
    background: goldenrod;
  }

  .toggle-button:hover{
    transform: rotate(90deg);
    transition: 500ms;
  }
.side-drawer{
    height: 100%;
    /* background-color: rgb(50, 49, 48); */
    background-image: 
    linear-gradient(rgba(0, 0, 0, .9), rgba(0, 0, 0, .9)),
    url(/static/media/cornetto.22746cf2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 300px;
    z-index: 200;
    transform: translateX(-100%);
    transition:  300ms ease-out;
}

.side-drawer.open{
    transform: translateX(0);
}

.side-drawer li{
    margin-bottom: 4%;
}

.side-drawer ul{
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
}

.drop-down{
    color: black;
}

.side-drawer a{
    color: white;
    text-decoration: none;

}

.dropdown-item a{
    color: black;
}
.side-drawer a:hover,
.side-drawer a:active{
    color: goldenrod;
}

.header{
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgb(235, 210, 192);

}

.custom-nav-link{
    padding: 1rem;
}

.social{
    color: rgb(235, 210, 192);
    display: flex;
    justify-content: space-around;
}




.toolbar {
  position: fixed;
  background-color: rgba(50, 49, 48, 0.8);
  width: 100%;
  min-height: 56px;
  top: 0;
  left: 0;
  z-index: 200;
  padding: 10px 0;
}

.toolbar img {
  height: 75px;
}

.toolbar__navigation {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}

.toolbar__logo {
  margin-left: 1rem;
}
.toolbar__logo a {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
}

/* .spacer{
    flex: 1;
} */

.header img {
  margin: 0 15px;
}

.toolbar_navigation-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.toolbar_navigation-items li {
  padding: 0 0.5rem;
}

.toolbar_navigation-items a {
  color: white;
  text-decoration: none;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
  color: rgb(185, 205, 207);
}

.cart{
    height: 100%;
    background-image: 
    linear-gradient(rgba(0, 0, 0, .9), rgba(0, 0, 0, .9)),
    url("/static/media/cornetto left.c6b7d329.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;    box-shadow: 2px 0px 5px rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    right: -300px;
    width: 70%;
    max-width: 300px;
    z-index: 200;
    transform: translateX(-100%);
    transition:  300ms ease-out;
}



.cart.open{
    transform: translateX(0);
}

.cart-buttons{
    display: flex;
    flex-direction: column;
}

.cart-lineitem{
    width: 100%;
    background-color: rgb(31, 31, 31);
    padding: 5px;
    border-radius: 5px;
    margin: 3px 0;
}

.cart-lineitem img{
    width: 100%;
}

.cart-lineitem-details{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 5px;
}

.cart-remove-link{
    text-align: right;
    color: rgb(100, 100, 100);
}

.cart-remove-link:hover{
    cursor: pointer;
    color: whitesmoke;
}

.cart li{
    margin-bottom: 4%;
}

.cart ul{
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    list-style: none;
}

.drop-down{
    color: black
}

.cart a{
    color: darkgrey;
    text-decoration: none;

}



.dropdown-item a{
    color: black;
}
.cart a:hover,
.cart a:active{
    color: goldenrod;
}

.empty-cart{
    margin-top: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header{
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgb(235, 210, 192);

}

.quantity-button{
    border: none;
    height: 20px;
    width: 20px;
    border-radius: 50px;
    /* padding-bottom: 10px; */
    background-color: transparent;
    color: grey;
    margin: 5px;
}

.quantity-button:hover{
    cursor: pointer;
    /* background-color: rgba(100, 100, 100, .5); */
    color: black;
}

.quantity-button-text{
    margin: 0 -4px 0 2px;
}

.social{
    color: rgb(235, 210, 192);
    display: flex;
    justify-content: space-around;
}

.drawer-logo{
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.drawer-logo img{
    margin: auto;
}


